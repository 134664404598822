
import { Component, Prop, Vue } from 'vue-property-decorator'
import { EventRegistrationPayload } from '@/../types/interfaces';
@Component
export default class EventbriteWidget extends Vue{
	@Prop({ required: true }) eventId: string;

	loading: boolean = true;
	get ElementUID(): string{
		return `eventbrite-widget-modal-trigger-${this.eventId}`;
	}

	onOrderComplete(payload: { orderId: string }): void{
		const orderPayload : EventRegistrationPayload = {
			eventbriteId: this.eventId,
			eventbriteOrderId: payload.orderId,
		};
		this.$emit('order-complete', orderPayload);
	}

	created(): void{
		this.mountEventbriteScript();
	}
	beforeDestroy(): void{
		this.unmountEventbriteScript();
	}

	initEBWidget(): void{
		// console.log("mounted: window['EBWidgets']", { EBWidgets: window['EBWidgets'] });
		window['EBWidgets'].createWidget({
			widgetType: 'checkout',
			eventId: this.eventId,
			modal: true,
			modalTriggerElementId: this.ElementUID,
			// iframeContainerId: this.ElementUID,
			// iframeContainerHeight: 425,  // Widget height in pixels. Defaults to a minimum of 425px if not provided
			onOrderComplete: this.onOrderComplete.bind(this),
		});
	}

	ebScript: HTMLScriptElement;
	mountEventbriteScript(): void{
		this.loading = true;
		this.ebScript = document.createElement('script');
		this.ebScript.setAttribute('src', 'https://www.eventbrite.ca/static/widgets/eb_widgets.js');
		this.ebScript.onload = () => {
			// console.log("EB Script loaded!");
			this.loading = false;
			this.initEBWidget();
		};
		this.ebScript.onerror = (e) => {
			console.error("EB Script Error", e);
			this.loading = false;
		};
		document.head.appendChild(this.ebScript);
	}
	unmountEventbriteScript(): void{
		document.head.removeChild(this.ebScript);
	}
}
